import React from "react";
import { useTranslation } from "react-i18next";
import icon1 from "../../assets/images/iconBtn.svg";
import icon2 from "../../assets/images/iconBtn2.svg";
import icon3 from "../../assets/images/iconBtn3.svg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-8 relative">
      <div className="bg-white w-[405px] h-[435px] rounded-2xl shadow-2xl">
        <h3 className="font-bold text-[24px] text-[#418087] mt-[20px] ml-[40px]">
          {t("Umumiy")}
        </h3>
        <p className="px-[30px] mt-[20px]">
          {t(
            "Chirchiq — Toshkent viloyatidagi shahar. Chirchiqdaryosining oʻng sohilida, Qorjontovning yon bagʻrida, 730 m balandlikda, Toshkentdan 30 km shim.sharqda joylashgan. Aholisi 2023-yil hisobidan 180,1 ming kishi. Umumiy maydoni 100 km.kv. Shaharda 44 mahalla mavjud. Shahardan poezd yoli oʻtgan. Shahar ichida 26 ta yonalishli avtobus va mikro avtobuslar qatnamoqda."
          )}
        </p>
        <img src={icon2} alt="icon" className="absolute left-[260px]" />
      </div>
      <div className="bg-white w-[405px] h-[435px] rounded-2xl shadow-2xl">
        <h3 className="font-bold text-[24px] text-[#418087] mt-[20px] ml-[40px]">
          {t("Tarix")}
        </h3>
        <p className="px-[30px] mt-[20px]">
          {t(
            "Tarix 1932-yilda daryo boʻyidagi Qirgʻizquloq, Qipchoq, Niyozbek qishloqlari oʻrnida yirik elektrkimyo qurilishi munosabati bilan vujudga kelgan. Yangi sanoat korxonalari qurilishi bilan aholi soni ham tez oʻsdi (1939-yil — 15 ming kishi, 1959-yil — 65 ming kishi, 1970-yil — 107,4 ming kishi). 1966—67 yillardan koʻp qavatli yirik panelli binolar qad koʻtarila boshlandi."
          )}
        </p>
        <img
          src={icon1}
          alt="icon2"
          className="absolute right-[438px] bottom-[-14px]"
        />
      </div>
      <div className="bg-white w-[405px] h-[435px] rounded-2xl shadow-2xl">
        <h3 className="font-bold text-[24px] text-[#418087] mt-[20px] ml-[40px]">
          {t("Tashkilotlar")}
        </h3>
        <p className="px-[30px] mt-[20px]">
          {t(
            "Tashkilotlar Ikkinchi jahon urushida halok boʻlgan chirchiqliklar xotirasiga oʻrnatilgan yodgorlik, madaniyat va istirohat bogʻlari, xiyobonlar, maktablar, poliklinika, kasalxona va boshqa bunyod etildi. Chirchiq oliy tank komandirmuhandislik bilim yurti, 25 umumiy taʼlim maktabi, 7 oʻrta maxsus bilim yurti, 12 kutubxona, 3 madaniyat muassasasi, muzey, madaniyat va istirohat bogʻi bor."
          )}
        </p>
        <img
          src={icon3}
          alt="icon3"
          className="absolute right-0 bottom-[-14px]"
        />
      </div>
    </div>
  );
};

export default RegionInfo;
