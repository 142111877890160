import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/kart.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#0A2647] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Chirchiq shahar hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.chirchiq.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            <a
              className="hover:text-gray-400 duration-300"
              href="https://www.instagram.com/chirchiq__rasmiy/?utm_medium=copy_link"
              target="_blank"
            >
              <FiInstagram size={26} />
            </a>
            {/* <a
              className="hover:text-gray-400 duration-300"
              href=""
              target="_blank"
            >
              <FiYoutube size={29} />
            </a> */}
            <a
              className="hover:text-gray-400 duration-300"
              href="https://t.me/CHIRCHIQ_RASMIY"
              target="_blank"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-gray-400 duration-300"
              href="https://www.facebook.com/people/Chirchiq-Rasmiy/pfbid0XNeG3QUsXUUH3yiuBvTaUXn4TLHkqR8XFvc7eX7WjhsvG3spAmVQeHWSz95TyYXCl/"
              target="_blank"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Chirchiq shahar, Amir Temur ko'chasi, 1-uy")}</p>
          <a
            href="https://www.google.com/maps/place/41%C2%B028'20.8%22N+69%C2%B034'55.7%22E/@41.472454,69.582127,16z/data=!4m4!3m3!8m2!3d41.472454!4d69.582127!5m1!1e4?entry=ttu"
            target="_blank"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 716 35 04")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
