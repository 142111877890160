import React from "react";
import { useGetStatistika } from "../../hooks/query";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";
import cart1 from "../../assets/images/cart1.png";
import cart2 from "../../assets/images/cart2.png";
import cart3 from "../../assets/images/cart3.png";
import cart4 from "../../assets/images/cart4.png";
import cart5 from "../../assets/images/cart5.png";

const Statistika = () => {
  const useGetStatistikaList = useGetStatistika();
  const { t } = useTranslation();
  console.log(useGetStatistikaList);
  if (useGetStatistikaList.isLoading) {
    return (
      <div className="flex justify-center h-[570px]">
        <Loader color="#014f86" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div className="h-[400px]">
      <h3 className="font-[700] text-center lg:text-[30px] text-[22px] text-#121221 my-[20px]">
        {t("Murojaatlar statistikasi")}
      </h3>

      {useGetStatistikaList.data?.map((item) => (
        <div key={item.id} className="flex justify-evenly text-center shadow-xl h-[250px] rounded-2xl items-center">
          <div className="flex flex-col items-center border-2 p-2 rounded-xl w-[200px]">
            <img src={cart5} alt="" width={60} />
            <p className="font-[600] text-[36px] ">{item.appeals}</p>
            <p className="text-[18px] font-[500]">{t("Umumiy murojaatlar")}</p>
          </div>
          <div className="flex flex-col items-center border-2 p-2 rounded-xl w-[200px]">
            <img src={cart4} alt="" width={60} />
            <p className="font-[600] text-[36px] ">{item.looking_appeals}</p>
            <p className="text-[18px] font-[500]">{t("Jarayonda")}</p>
          </div>
          <div className="flex flex-col items-center border-2 p-2 rounded-xl w-[200px]">
            <img src={cart3} alt="" width={60} />
            <p className="font-[600] text-[36px] ">{item.expertiza}</p>
            <p className="text-[18px] font-[500]">{t("Qanoatlantirildi")}</p>
          </div>
          <div className="flex flex-col items-center border-2 p-2 rounded-xl w-[200px]">
            <img src={cart2} alt="" width={60} />
            <p className="font-[600] text-[36px] ">{item.understand}</p>
            <p className="text-[18px] font-[500]">{t("Tushuntirildi")}</p>
          </div>
          <div className="flex flex-col items-center border-2 p-2 rounded-xl w-[200px]">
            <img src={cart1} alt="" width={60} />
            <p className="font-[600] text-[36px] ">{item.canceled}</p>
            <p className="text-[18px] font-[500]">{t("Rad etildi")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistika;
